import React from "react"

import {ButtonGroup, Button, Form} from "react-bootstrap"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from "moment";

class StyleForm extends React.Component {
  constructor(options) {
    super(options);
    this.state = { gender: "", date: moment().format(), isPasswordVisible: false, password: '' };
  }

  /* Toggle password visibility */
  togglePwd() {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  }

  /* Switch selected button */
  switchBtn(e) {
    this.setState({
      gender: e.target.name
    })
  }

  handleChange(date) {
    this.setState({
      date: date
    })
  }

  render() {
    return(
      <Layout>
        <SEO title="Style Guide" />
        <h1 className="with-border-top with-border-bottom">Form components</h1>
        <Form>
          {/* Besoin de this.state.date pour fonctionner */}
          <Form.Group controlId="formGroupEmail">
            <Form.Label className="required">Datepicker</Form.Label><span className="icon-check ml-2"></span>
            <DatePicker className="form-control"
              selected={this.state.date}
              onChange={this.handleChange.bind(this)}
            />
          </Form.Group>


          <Form.Group>
            <Form.Label className="required">Input is OK</Form.Label><span className="icon-check ml-2"></span>
            <Form.Control type="text" />
          </Form.Group>


          <Form.Group>
            <Form.Label className="required">Input email</Form.Label><span className="icon-check ml-2"></span>
            <Form.Control type="email" />
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Input has error</Form.Label>
            <Form.Control type="text" className="error" />
            <small className="form-text form-validate-ok"><span className="icon-check mr-2"></span>Capital letter</small>
            <small className="form-text form-validate-error"><span>X</span>At least 8 characters</small>
          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Input password</Form.Label>
            <div className="password-wrapper">
              {this.state.isPasswordVisible ?
                <input
                  className="form-control"
                  type='text'
                  onChange={(e) => this.setState({password: e.target.value})}
                  value={this.state.password} />
                :
                <input
                  className="form-control"
                  type='password'
                  onChange={(e) => this.setState({password: e.target.value})}
                  value={this.state.password} />}

              <span className={this.state.isPasswordVisible? "icon-eye toggle-pwd grey-border" : "icon-eye toggle-pwd"} onClick={this.togglePwd.bind(this)}></span>
            </div>
          </Form.Group>

          <Form.Group>
            <ButtonGroup aria-label="gender">
              <Button className={this.state.gender == "m" ? "selected" : ""} name="m" onClick={this.switchBtn.bind(this)}>M.</Button>
              <Button className={this.state.gender == "mme" ? "selected" : ""} name="mme" onClick={this.switchBtn.bind(this)}>Mme.</Button>
            </ButtonGroup>
          </Form.Group>



          <Form.Group>

            <Form.Label className="required">Number plate</Form.Label> <span className="icon-check ml-2"></span>

            <div className="row">
              <div className="col-md-4">
                <div className="licence-plate">
                  <Form.Control type="text" className="" placeholder="AA-123-AA" pattern="^[A-Z]{2} ?- ?\d{3} ?- ?[A-Z]{2}$" maxLength="9" />
                </div>
              </div>
              <div className="col-md-4">
                  <button type="submit" className="button red w-100 disabled">ADD</button>
              </div>

            </div>

          </Form.Group>


          <Form.Group>

            <Form.Label className="required">Kilometers</Form.Label> <span className="icon-check ml-2"></span>

              <div className="input-units" data-units="KMS">
                <Form.Control type="number" className="" placeholder="100000" />
              </div>

          </Form.Group>


          <p>Choose an exact date or a period</p>


          <Form.Group>

            <div className="row">
              <div className="col-md-6">
                <div className="radio-date">
                  <Form.Control type="radio" name="latest-maintenance" className="" id="latest-maintenance-1" value="Less than 1 month" /> <label for="latest-maintenance-1" className="button white">Less than 1 month</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="radio-date">
                  <Form.Control type="radio" name="latest-maintenance" className=" " id="latest-maintenance-2" value="3 - 6 months" /> <label for="latest-maintenance-2" className="button white">3 - 6 months</label>
                </div>
              </div>
            </div>

          </Form.Group>

          <Form.Group>
            <Form.Label className="required">Input with icon</Form.Label>
            <div className="input-with-icon">
              <Form.Control type="text" placeholder="Enter your location" />
              <span className="icon-search icon"></span>
            </div>
          </Form.Group>


          <Form.Group>
            <small className="form-text"><a href="">Pourquoi demandons-nous votre date de naissance?</a></small>
          </Form.Group>


          <br />
          <br />

          <h1 className="with-border-top with-border-bottom">YOUR COMMUNICATION PREFERENCES:</h1>

          <h3 className="mb-2">Service Reminders</h3>

          <p className="mb-4"><em>Nissan will remind you the date of your next interview.</em></p>

          <Form.Group>
            <div className="d-flex flex-wrap">
              <div className="mr-4">
                <div className="checkbox-custom">
                  <Form.Control type="checkbox" name="preference-sms" id="preference-sms" />
                  <Form.Label for="preference-sms">SMS</Form.Label>
                </div>
              </div>

              <div className="mr-4">
                <div className="checkbox-custom">
                  <Form.Control type="checkbox" name="preference-mail" id="preference-mail" />
                  <Form.Label for="preference-mail">E-MAIL*</Form.Label>
                </div>
              </div>

              <div className="">
                <div className="checkbox-custom">
                  <Form.Control type="checkbox" name="preference-notice" id="preference-notice" />
                  <Form.Label for="preference-notice">Push notification</Form.Label>
                </div>
              </div>
            </div>
          </Form.Group>

          <Form.Group>
            <div className="d-flex flex-wrap">

              <div className="mr-4">
                <div className="radio-custom">
                  <Form.Control type="radio" name="radio-input" id="radio-input-1" />
                  <Form.Label for="radio-input-1">FR</Form.Label>
                </div>
              </div>

              <div className="mr-4">
                <div className="radio-custom">
                  <Form.Control type="radio" name="radio-input" id="radio-input-2" />
                  <Form.Label for="radio-input-2">NL</Form.Label>
                </div>
              </div>

            </div>
          </Form.Group>

          <Form.Group>

            <Form.Label>Phone number</Form.Label> <span className="icon-check ml-2"></span>

            <div className="phone-number" data-indicatif="+31">
              <Form.Control type="text" placeholder="_-__-__-__-__" pattern="^\d{9}$" maxLength="9" />
            </div>

          </Form.Group>

          <Form.Group className="mb-4 text-right">
            <button type="submit" className="button red d-inline-block disabled">Next</button>
          </Form.Group>

          <h2 className="text-uppercase">Text area</h2>

          <Form.Group>
            <Form.Label className="required">Label</Form.Label>
            <textarea placeholder="PLACEHOLDER" name="textarea-exemple" className="form-control" rows="6"></textarea>
          </Form.Group>

          <Form.Group className="mb-5">
            <Form.Label className="required">Label</Form.Label>

            <textarea placeholder="PLACEHOLDER" name="textarea-exemple-2" className="form-control" rows="6" value={`Messages in text areas can go on multiple lines with some line Breaks.\n\nBe sure to take them into account!`} ></textarea>
          </Form.Group>


          <Form.Group className="mb-4">
            <button type="submit" className="button white w-100 mx-auto">Sign In</button>
          </Form.Group>

          <Form.Group className="mb-4">
            <button type="submit" className="button red w-100 mx-auto">Finish</button>
          </Form.Group>

          <div className="required-field mb-5">*Required fields</div>




          <h2 className="text-uppercase">Tooltip</h2>




          <p className="show-more" id="show-more-1">
            According to the law on the protection of French data (Law on Data Protection) of 6 January 1978, modified and GDPR, you have the right to access, query, modify, delete your information and limit the right of opposition to exploration or for a legitimate reason, including profiling, the right to data portability, the right to file a complaint with the French authority "Commission Nationale Informatique et Libertés (CNIL)" and a right to set guidelines for what happens to your personal data when you switch to delegue-protection-donnees@highco.com writing enclosing a copy of ID with your signature. Please indicate "HighCo" in the subject of your application.
          </p>

          <div className="text-center mb-4">
            <a href="#show-more-1" className="btn-show-more">Read More v</a>
          </div>



        </Form>



      </Layout>
    )
  }
}

export default StyleForm
